@font-face {
  font-family: 'WIVFont';
  src: local('WIVFont'),
    url('./Fonts/WIVFont.ttf') format('woff2')
}

.container {
  background-repeat: no-repeat;
  overflow: hidden;
}

.videooverlay {
  position: absolute;
  bottom: 25%;
  left: 12.5%;
  right: 12.5%;
  overflow: hidden;

}

.LogoOverlay {
  width: 100%;
  height: auto;
}

.videoTag {
  z-index: 100;
  height: 100vh;
  object-fit: cover;

}

.Tabletstyle {
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

}

.headline {
  color: #fff;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  z-index: 30000;
}

.headline4 {
  color: #a2a396;
  font-family: "WIVFont";
  font-weight:500;
  font-size: 80px;
  z-index: 30000;
  margin-top: -5%;
}
.active{
  background-color: #009900;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2B2A2A;
  color: white;
  position: sticky;
  top: 0;
}

nav .title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

nav ul {
  display: flex;
  padding-left: 0%;
}

nav ul li {
 list-style: none; 
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}

nav ul li a:hover {
  background-color:#009900 ;
  color: white
}

nav .menu {
  display: none;
  position: relative;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}
.banner1 {
  width: 100%;
  height: 100vh;
  background: rgb(2, 0, 36);
  background: #1A191B;
  background-size: auto;
  border-bottom: 30px solid #009900;
}

@media (max-width: 480px) {
  nav .menu {
      display: flex;
  }

  nav {
      flex-direction: column;
      align-items: flex-start;
  }

  nav ul {
    position: relative;
    list-style: none;
    font-family: "WIVFont";
    color: #fff;
  }

  nav ul.open {
    position: relative;
    list-style: none;
    font-family: "WIVFont";
    color: #fff;
  }

  nav ul li {
    position: relative;
    list-style: none;
    font-family: "WIVFont";
    color: #fff;
  }

  nav ul li a {
    position: relative;
    list-style: none;
    font-family: "WIVFont";
    color: #fff;
  }
  
.videoTag {
  height: 80vh;
}
.banner1 {
  height: 80vh;
}

.videooverlay {
  width: 100%;
  bottom: 35%;
  left: 0%;
  right: 0%;

}

.headline {
  font-size: 25px;
}

}


.nav-logo {
  height: auto;
  width: 25%;
}

.nav-logo-mobile {
  height: auto;
  width: 25%;
  margin-top: 2%;
}

@media (max-width: 480px) {

  .nav-logo-mobile {
    margin-top: 0%;
    margin-bottom: -5%;
    width: 50%;
  }

}




.NavbarPosition {
  z-index: 500000;
}

.banner {
  width: auto;
  height: 100%;
  background: rgb(2, 0, 36);
  background: #1A191B;
  background-size: auto;

}

.Scrollext {
  position: absolute;
  color: white;
  bottom: 20%;
  left: 12.5%;
  right: 12.5%;
  top: 20%;
  font-family: "WIVFont";
  z-index: 200000;
  -webkit-animation: fadeinout 4s linear scroll;
  animation: fadeinout 4s linear scroll;
}

.stickyblock {
  position: absolute;
  bottom: 50%;
  left: 12.5%;
  right: 12.5%;
  margin-top: 50%;

  color: #fff;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  z-index: 30000;
}

.box {
  height: 100px;
  position: absolute;
  left: 100px;
  font-size: 50px;
  color: #fff;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  z-index: 30000;
}

.b {
  top: 400px;
}

.c {
  top: 400px;
}

.d {
  top: 400px;
}

.e {
  top: 450px;
}

.f {
  top: 500px;
}

.g {
  top: 550px;
}

.h {
  top: 600px;
}

.i {
  top: 650px;
}

.j {
  top: 700px;
}

.k {
  top: 750px;
}

.iz {
  top: 800px;
}
.jz {
  top: 850px;
}

.l {
  top: 900px;
}

.m {
  top: 950px;
}

.lz {
  top: 1000px;
}

.n {
  top: 500px;
}

.o {
  top: 500px;

}

.p {
  top: 500px;
  left: 50%;
  text-align: left;
  margin-left: 10%;
}

.q {
  top: 500px;
  left: 50%;
  text-align: left;
  margin-left: 10%;
}

.r {
  top: 500px;
  left: 50%;
  text-align: left;
  margin-left: 10%;

}

.s {
  top: 500px;
  left: 50%;
  text-align: left;
  margin-left: 10%;
}

.t {
  top: 500px;
  left: 50%;
  text-align: left;
  margin-left: 10%;
}

.u {
  top: 500px;
  left: 50%;
  text-align: left;
  margin-left: 10%;
}

.w {
  top: 850px;
}

.x {
  top: 950px;
}

.y {
  top: 1000px;
}

.z {
  top: 1050px;
}

.az {
  top: 1100px;
}

.bz {
  top: 1150px;
}

.cz {
  top: 1200px;
}

.dz {
  top: 450px;
}

.ez {
  top: 500px;
}

.fz {
  top: 550px;
}

.gz {
  top: 600px;
}

.hz {
  top: 650px;
}

.mz {
  top: 350px;
}


.intro {
  height: 100px;
  position: absolute;
  left: 100px;
  font-size: 50px;
  color: #fff;

  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  z-index: 30000;
}

.intro h1,
.intro h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: white;
  opacity: 0;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  z-index: 30000;
}

.secondrow {
  font-weight: 200;
  font-size: 30px;
  float: left;
}

.secondrow2 {
  font-weight: 200;
  font-size: 22px;
  line-height: 0.1;
}

.Footer-head {
  background-color: #009900;
}

.Footer {
  background-color: black;
  font-family: WIVFont;
  color: white;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.foot-logo {
  height: auto;
  max-width: 40%;
  align-items: right;
}

.Social {
  -webkit-filter: invert(1);
  filter: invert(1);
  height: auto;
  max-width: 5%;
  margin-right: 2rem;
  float: right;
}


@media (max-width: 480px) {
  .Social {
    max-width: 45%;
    display: flex;
    justify-content: center;
    float:0;
    margin-right: 1.5rem;

  }

  .Footer {
    padding-left: 0rem;
  }

  .foot-logo {
    max-width: 100%;
  }
}
.yt-style {

  padding-top: 15%;
  width: 90rem;
  height: 50rem;
  overflow: hidden;
  position: relative;
  align-content: center;
  display: block;
  margin: 0 auto;
  border-radius: 15px;
  padding-bottom: 15%;

}



/* unvisited link */
a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: lightslategray;
  text-decoration: none;
}

/* selected link */
a:active {
  color: white;
  text-decoration: none;
}

.Datenschutz {
  font-family: WIVFont;
  color: #fff;
  background-color: #1A191B;
  padding-left: 10%;
  padding-right: 10%;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.markercluster-map {
  height: 50vh;
  width: 100%;
}

.leaflet-div-icon {
  background-color: black;
}

.Blocktext {
  font-family: WIVFont;
  color: #fff;
  background-color: #1A191B;
  padding-left: 10%;
  padding-right: 10%;
  font-weight: 300;
}

:root {
  --color-bg: #1A191B;
  --color-bg-variant: #1A191B;
  --color-primary: #009900;
  --color-primary-variant: #009900;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
}

body {
  font-family: "WIVFont";
  color: var(--color-white);
  line-height: 1.7;
  background: #1A191B;
  background-blend-mode: screen;
  margin: 0px;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);

}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

.VideoPlayer {
  width: 10%;
  height: auto;
}

.NewsEmbed {
  border: "0";
  width: "100%";
  height: "40vh";
  pointer-events: "none"
}

.Contact {
  background-color: #009900;
  border-radius: 42px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.Contact:hover {
  background-color: #5cbf2a;
}

.Contact:active {
  position: relative;
  top: 1px;
}

.wrapper2 {
  background-image: linear-gradient(#1A191B, #5B595A);
}

.wrapper3 {
  background-image: linear-gradient(#5B595A, #1A191B);
}

.Abgestimmt {
  background-image: #1A191B;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202020;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6a6a6a;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Tree {
  width: 40%;
  vertical-align: top;
  text-align: left;
  padding-left: 5%;
  padding-top: 10%;
}

.Textside {
  vertical-align: top;
  text-align: left;
  padding-right: 10%;
}

.Headline1 {
  font-size: 70px;
  font-weight: 600;
}

.Headline2 {
  font-size: 40.8px;
  font-weight: 500;
  margin-top: -40px;
}

.Textabout {
  font-size: 20px;
}


.FilterButton1 {
  background-color: #009900;
  border-radius: 42px;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.FilterButton1:hover {
  background-color: #5cbf2a;
}

.FilterButton1:active {
  position: relative;
}


.FilterButton2 {
  background-color: #5A595B;
  border-radius: 42px;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.FilterButton2:hover {
  background-color: #727272;
}

.FilterButton2:active {
  position: relative;
}


.FilterButton3 {
  background-color: #A2A297;
  border-radius: 42px;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.FilterButton3:hover {
  background-color: #bcbcb3;
}

.FilterButton3:active {
  position: relative;
}